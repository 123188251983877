



































































import { defineComponent, reactive, useContext } from '@nuxtjs/composition-api'
import forEach from 'lodash/forEach'
export default defineComponent({
  setup(_, context: any) {
    const { $auth } = useContext()
    const state = reactive({
      valid: true,
      email: '',
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      password: '',
      passwordRules: [(v: string) => !!v || 'Password is required'],
      checkbox: false,
      alert: false,
      errorList: ''
    })

    const validate = () => {
      context.refs.form.validate()
      if (state.valid) {
        login()
      }
    }
    const reset = () => {
      context.refs.form.reset()
    }
    const login = async () => {
      try {
        const postData = {
          email: state.email,
          password: state.password
        }
        const response = await $auth.loginWith('local', {
          data: postData
        })
        const { status, data }: any = response
        if (status === 200) {
          setUser(data)
        } else if (status === 202) {
          showBackendValidations(response)
        } else if (status === 203) {
          state.errorList = 'username or password is incorrect please try again'
          state.alert = true
        }
      } catch (error) {
        console.error(error)
      }
    }
    const setUser = (user: object) => { // CREATE A PROPER INTERFACE
      $auth.setUser(user)
    }
    const showBackendValidations = (responseData: any) => {
      state.errorList = ''
      if (!responseData) {
        state.errorList = 'Something went wrong'
        state.alert = true
        return
      }
      if (responseData.data.email && responseData.data.email.length > 0) {
        forEach(responseData.data.email, (err: string, index: number) => {
          state.errorList += `${err}${
            responseData.data.email.length - 1 !== index ? '\n \n' : ''
          }`
        })
        state.alert = true
      } else if (
        responseData.data.password &&
        responseData.data.password.length > 0
      ) {
        forEach(responseData.data.password, (err: string, index: number) => {
          state.errorList += `${err}${
            responseData.data.password.length - 1 !== index ? '\n \n' : ''
          }`
        })
        state.alert = true
      } else if (
        responseData.data.c_password &&
        responseData.data.c_password.length > 0
      ) {
        forEach(responseData.data.c_password, (err: string, index: number) => {
          state.errorList += `${err.replace('c password', 'confirm password')}${
            responseData.data.c_password.length - 1 !== index ? '\n \n' : ''
          }`
        })
        state.alert = true
      }
    }
    const hideAlert = () => {
      setInterval(() => {
        state.alert = false
      }, 5000)
    }
    hideAlert()
    return {
      state,
      validate,
      reset
    }
  }
})
